.App {
  height: 100%;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
.title {
  font-size: 3rem;
  font-weight: 700;
  margin: 0;
  text-align: center;
  margin-top: 10vh;
}
.slideshow-title {
  font-size: 2rem;
  font-weight: 700;
  margin: 0.5rem;
  text-align: center;
}
.slideshow-content-container {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  gap: 5%;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}
.content-container {
  display: contents;
}
.layout-container {
  display: flex;
  flex-flow: column;
  gap: 0px;
  padding: 0px;
  position: relative;
  width: 100%;
  height: 100%;
}
.description {
  place-content: center flex-start;
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-flow: column;
  gap: 0px;
  height: min-content;
  overflow: hidden;
  padding: 2px;
  position: relative;
  width: 40%;
}
.divider {
  width: 100%;
  height: 1px;
  background-color: #e0e0e0;
  margin: 1rem 0;
}
#section-1 {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-flow: column;
  gap: 0px;
  padding: 0px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  scroll-snap-align: center;
  scroll-behavior: smooth;
}
#section-2 {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-flow: column;
  gap: 0px;
  padding: 0px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  scroll-behavior: smooth;
}
#section-3 {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-flow: column;
  gap: 0px;
  padding: 0px;
  position: relative;
  display: flex;
  justify-content: center;
  background-color: #ffffff;
  align-items: center;
  overflow: hidden;
  scroll-behavior: smooth;
  scroll-snap-align: center;
}
.immerse-text {
  font-size: 1rem;
  color: gray;
}
.text-slideshow {
  display: flex;
  flex-flow: row;
  gap: 0px;
  padding: 0px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.side-image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  height: 100%;
  object-fit: fill;
  margin-left: 5%;
  border-radius: 10px;
  aspect-ratio: 5/3;
}
.side-title {
  width: 20%;
}
.side-title-text {
  font-size: 3rem;
  font-weight: 700;
  margin: 0;
}
.text-book-buttons {
  display: flex;
  flex-flow: row;
  gap: 0px;
  padding: 0px;
}
.book-button{
  margin-right: 0.5rem;
  max-height: 2rem;
  max-width: 11rem;
  min-height: 2rem;
  min-width: 11rem;
  font-size: 1rem;
  text-align: center;
  justify-content: center;
  align-items: center;
  color:  white;
  background-color: #0f426e;
}
@media (max-width: 1600px){
  .side-title-text {
    font-size: 2.5rem;
    font-weight: 700;
    margin: 0;
  }
}
@media (max-width: 1300px) {
  .side-title-text {
    font-size: 2rem;
    font-weight: 700;
    margin: 0;
  }
}
@media (max-width: 768px) {
  .logo {
    width: 50px;
    height: 50px;
    margin: 0 10px;
  }
  .logo-container {
    width: 20%;
  }
  .slideshow-content-container {
    padding: 10px;
  }
  .description {
    width: 80%;
  }
  .review-container {
    padding: 10px;
  }
  .title {
    font-size: 2rem;
  }
  .side-title-text {
    font-size: 2rem;
  }
  .immerse-text {
    font-size: 0.8rem;
  }
  .side-title {
    width: 30%;
    margin-right: 5%;
  }
  .text-slideshow {
    flex-flow: column;
    height: 100%;
  }
  .book-button {
    flex-flow: row;
    width: 100%;
  }
}
@media (max-width: 480px) {
  .logo {
    width: 50px;
    height: 50px;
    margin: 0 10px;
  }
  .logo-container {
    width: 10%;
  }
  .title {
    font-size: 2rem;
  }
  .description {
    width: 80%;
  }
  .review-container {
    padding: 10px;
  }
  .side-title-text {
    font-size: 1.5rem;
  }
  .immerse-text {
    font-size: 0.8rem;
  }
  .side-title {
    width: 80%;
    margin-right: 5%;
  }
  .text-slideshow {
    flex-flow: column;
    text-align: center;
  }
  .book-button {
    flex-flow: row;
    min-width: 5rem;
    min-height: 3rem;
    font-size: 1rem;
    margin-right: 0.6rem;
    margin-left: 0.6rem;
  }
  .side-image{
    margin-left: 0%;
  }
  .side-title{
    margin: 0%;
  }
}
@media (max-width: 300px) {
  .title {
    font-size: 1rem;
  }
  .side-title-text {
    font-size: 1rem;
  }
  .immerse-text {
    font-size: 0.5rem;
  }
  .side-title {
    width: 30%;
    margin-right: 5%;
  }
  .text-slideshow {
    flex-flow: column;
  }
  .book-button {
    flex-flow: row;
    width: 100%;
  }
}
