.gallery-main {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-flow: column;
    gap: 0px;
    padding: 0px;
    position: relative;
    justify-content: center;
    align-items: center;
}
.gallery-section {
    width: 100%;
    height: 100%;
    gap: 0px;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.gallery-carousel {
    width: 40em;
    gap: 0px;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}