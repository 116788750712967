.slideshow-containerq6h7r3k9 {
    width: 35%;
    height: 35%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0.5rem 0.5rem 0.5rem 0.5rem;

}
.slideshow-image-container-top{
    width: 140%;
    height: 140%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    margin: 0.5rem 0.5rem 0.5rem 0.5rem;
}
.slideshow-image-container-bottom{
    width: 140%;
    height: 140%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    margin: 0.5rem 0.5rem 0.5rem 0.5rem;
}
.slideshow-image-container-1 {
    width: 100%;
    height: 100%;
    object-fit: fill;
    margin: 0.5rem 0.5rem 0.5rem 0.5rem;
    border-radius: 10px;
    aspect-ratio: 5/3;
}
.slideshow-image-container-2 {
    width: 100%;
    height: 100%;
    object-fit: fill;
    margin: 0.5rem 0.5rem 0.5rem 0.5rem;
    border-radius: 10px;
    aspect-ratio: 5/6;
}
.slideshow-image-container-3 {
    width: 100%;
    height: 100%;
    object-fit: fill;
    margin: 0.5rem 0.5rem 0.5rem 0.5rem;
    border-radius: 10px;
    aspect-ratio: 5/6;
}
.slideshow-image-container-4 {
    width: 100%;
    height: 100%;
    object-fit: fill;
    margin: 0.5rem 0.5rem 0.5rem 0.5rem;
    border-radius: 10px;
    aspect-ratio: 5/3;
}
.slideshow-image-1 {
    width: 100%;
    height: 100%;
    object-fit: fill;
    border-radius: 10px;
}
.slideshow-image-2 {
    width: 100%;
    height: 100%;
    object-fit: fill;
    border-radius: 10px;
}
.slideshow-image-3 {
    width: 100%;
    height: 100%;
    object-fit: fill;
    border-radius: 10px;
}
.slideshow-image-4 {
    width: 100%;
    height: 100%;
    object-fit: fill;
    border-radius: 10px;
}
@media (max-width: 480px) {
    .slideshow-containerq6h7r3k9 {
        width: 90%;
        height: 90%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 0.5rem 0.5rem 0.5rem 0.5rem;
    }
    .slideshow-image-container-top{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: column;
        margin: 0.5rem 0.5rem 0.5rem 0.5rem;
    }
    .slideshow-image-container-bottom{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: column;
        margin: 0.5rem 0.5rem 0.5rem 0.5rem;
    }
    .slideshow-image-container-1 {
        width: 100%;
        height: 100%;
        object-fit: fill;
        margin: 0.5rem 0.5rem 0.5rem 0.5rem;
        border-radius: 10px;
        aspect-ratio: 5/3;
    }
    .slideshow-image-container-2 {
        width: 100%;
        height: 100%;
        object-fit: fill;
        margin: 0.5rem 0.5rem 0.5rem 0.5rem;
        border-radius: 10px;
        aspect-ratio: 5/6;
    }
    .slideshow-image-container-3 {
        width: 100%;
        height: 100%;
        object-fit: fill;
        margin: 0.5rem 0.5rem 0.5rem 0.5rem;
        border-radius: 10px;
        aspect-ratio: 5/6;
    }
    .slideshow-image-container-4 {
        width: 100%;
        height: 100%;
        object-fit: fill;
        margin: 0.5rem 0.5rem 0.5rem 0.5rem;
        border-radius: 10px;
        aspect-ratio: 5/3;
    }
    .slideshow-image-1 {
        width: 100%;
        height: 100%;
        object-fit: fill;
        border-radius: 10px;
    }
    .slideshow-image-2 {
        width: 100%;
        height: 100%;
        object-fit: fill;
        border-radius: 10px;
    }
    .slideshow-image-3 {
        width: 100%;
        height: 100%;
        object-fit: fill;
        border-radius: 10px;
    }
    .slideshow-image-4 {
        width: 100%;
        height: 100%;
        object-fit: fill;
        border-radius: 10px;
    }
}
