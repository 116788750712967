.settings-container {
    display: flex;
    width: 20%;
    justify-content: end;
    align-items: end;
  }
  .language-dropdown {
    margin-right: 0.5rem;
  }
  .flag {
    width: 20%;
    height: 20%;
  }
  .settings-dropdown {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .setting-buttons{
    width: 80%;
    height: 80%;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .language-button{
    width: 20%;
    height: 20%;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .current-flag {
    max-width: 25%;
    max-height: 25%;
    min-width: 10%;
    min-height: 10%;
    text-align: center;
    margin-right: 0.5rem;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .navbar {
    display: flex;
    flex-flow: row;
  }
  .navbar-container {
    display:flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    height: 10vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    color: white;
    z-index: 1;
  }
  .logo {
    width: 20%;
    height: 20%;
    margin: 2% 2% 2% 2%;
    border-radius: 10px;
  }
  .logo-container {
    width: 20%;
    display: flex;
    justify-content: start;
    align-items: start;
  }