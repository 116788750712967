.info-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.info-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.slideshow-container-xyz{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}