.infocards-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 2rem;
}
.infocard-container-1 {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 70%;
  height: 50%;
}
.infocard-container-2 {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-evenly;
  align-items: center;
  width: 70%;
  height: 50%;
}
.infocard-text-1 {
  display: flex;
  flex-direction: column;
  width: 30%;
  margin-right: 0.5rem;
}
.infocard-text-2 {
  display: flex;
  flex-direction: column;
  width: 30%;
  margin-right: 0.5rem;
}
.infocard-box-1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 35%;
  height: 80%;
  margin: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 10px;
}
.infocard-box-2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 35%;
  height: 80%;
  margin: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 10px;
}
.youtube-video {
  width: 100%;
  height: 100%;
  object-fit: fill;
  margin: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 10px;
}
@media (max-width: 480px) {
  .infocards-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-top: 2rem;
  }
  .infocard-container-1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50%;
  }
  .infocard-container-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50%;
  }
  .infocard-text-1 {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-right: 0.5rem;
  }
  .infocard-text-2 {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-right: 0.5rem;
  }
  .infocard-box-1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80%;
    margin: 0.5rem 0.5rem 0.5rem 0.5rem;
    border-radius: 10px;
  }
  .infocard-box-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80%;
    margin: 0.5rem 0.5rem 0.5rem 0.5rem;
    border-radius: 10px;
  }
  .youtube-video {
    width: 100%;
    height: 100%;
    object-fit: fill;
    margin: 0.5rem 0.5rem 0.5rem 0.5rem;
    border-radius: 10px;
  }
  .infocard-text-1 {
    font-size: 0.8rem;
    font-weight: 400;
    margin: 0;
    text-align: center;
  }
  .infocard-text-2 {
    font-size: 0.8rem;
    font-weight: 400;
    margin: 0;
    text-align: center;
  }
  .youtube-video {
    width: 80%;
    height: 100%;
    object-fit: fill;
    margin: 0.5rem 0.5rem 0.5rem 0.5rem;
    border-radius: 10px;
  }
}