.review-container-1 {
  display: flex;
  width: 100%;
  height: 80%;
  overflow: hidden;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
}
.review-container {
  display: flex;
  flex-flow: row;
  width: 60%;
  height: 45%;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  flex-wrap: nowrap;
  position: relative;
}
.reviews-box-container {
  animation: scroll 20s linear infinite;
  display: flex;
  flex-flow: row;
  height: 80%;
  justify-self: flex-start;
}
.review-box:hover .reviews-box-container {
  animation-play-state: paused;
}
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
.review-container:before,
.review-container:after {
  position: absolute;
  top: 0;
  width: 10%;
  height: 100%;
  content: "";
  z-index: 2;
}
.review-container:before {
  left: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 0), white);
}
.review-container:after {
  right: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
}

.review-box-1 {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
  background-color: #b6d6f2;
  color: #051433;
  border-radius: 10px;
  margin-left: 100px;
  margin-right: 100px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
}
.review-box {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin: 0.5rem 0.5rem 0.5rem 0.5rem;
  width: 100%;
  height: 100%;
}
.rating-box {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 30%;
}
.rating-stars {
  display: flex;
  flex-flow: column;
  justify-content: top center;
  margin: 0 0.5rem 0 0.5rem;
  width: 80%;
  height: 100%;
}
.rating-name {
  font-size: 1.3rem;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: start;
  width: 80%;
  height: 50%;
}
.profile-review-image {
  width: 100%;
  height: 100%;
  object-fit: fill;
  border-radius: 50%;
  margin: 0 0.5rem 0 0.5rem;
}
.rating-profile-icon {
  width: 30%;
}
.review-box-text {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60%;
  justify-content: center;
  align-items: center;
}
@media (max-width: 480px) {
  .review-box {
    width: 40%;
    height: 100%;
    margin: 0.5rem 0.5rem 0.5rem 0.5rem;
  }
  .review-container-1 {
    width: 100%;
  }
  .review-container {
    width: 100%;
  }
  .reviews-box-container {
    animation: scroll 40s linear infinite;
  }
}
